// Message.tsx
import React from "react";
import robotImage from "../assets/icons/robot_icon.png";
import userIcon from "../assets/icons/user_icon.png";

interface MessageProps {
  type: string;
  text: string;
  timestamps:string
}

const Message: React.FC<MessageProps> = ({ type, text,timestamps }) => {
  const avatharSrc = type === "sender" ? robotImage : userIcon;
  const avatharName = type === "sender" ? "Robot" : "Prasana";
  const backgroundColor = type === "sender" ? "rgba(134, 180, 238, 0.50)" : "#E6EEF4";
  const borderRadius = type === "sender" ? "0px 20px 20px 20px" : "20px 20px 0px 20px";
  const margin = type === "sender" ? "10px 0px" : "15px 0px";


  return (
    <div className={type === "sender" ? "sender-div-container" : "receiver-div-container"}>
      <div className="robot-name-img-container">
        <img src={avatharSrc} className="avathar-chat-bot" alt="Avatar" />
        <p>{avatharName}</p>
      </div>
      <div className="msg-content-div" style={{ backgroundColor, borderRadius, margin }}>
        {text}
      </div>
      <p className="chat-box-reply-time">{timestamps}</p>
    </div>
  );
};

export default Message;
