import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { SocketProps } from "../Types";

type SocketContextType = {
  socket1: any;
  socket2: any;
  transcriptData: any
  userId:string
};

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocketContext must be used within a SocketProvider");
  }
  return context;
};

const user ='65df1dd3295c753309b534c5';

const SocketProvider: React.FC<SocketProps> = ({ children }) => {

  const [transcriptData, setTranscriptData] = useState<any>();

  function generateRandomId(length: number) {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomId = '';
    for (let i = 0; i < length; i++) {
      randomId += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomId;
  }

  const userId = generateRandomId(10)

  const socket1 = io(`${process.env.REACT_APP_SOCKET_URL}/?id=${user}`, {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 5000,
  });


  const socket2 = io(`${process.env.REACT_APP_MICROSERVICE_SOCKET_URL}/?userId=${userId}`, {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 5000,
  });


  useEffect(() => {
    socket1.on("connect", () => {
      console.log(`User connected to ${process.env.REACT_APP_SOCKET_URL} with id-${user}`);
    });

    socket2.on("connect", () => {
      console.log(`User connected to ${process.env.REACT_APP_MICROSERVICE_SOCKET_URL} with id-${userId}`);
    });

    return () => {
      socket1.disconnect();
      socket2.disconnect();
    };
  }, [socket1, socket2]);

  const contextValue: SocketContextType = { socket1, socket2, transcriptData ,userId};

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
