import Message from "./Message";

function ChatApp({ showChat, setShowChat, messages }: any) {

  return (
    <div className={`chat-box-main-comtainer${showChat ? "" : " hidden"}`}>
      <h2>Conversations</h2>
      <div className="chat-box-msg-container">
        {messages && messages.map((message:any, index:number) => (
          <Message key={index} type={message.type} text={message.text} timestamps={message.timestamps}/>
        ))}
      </div>
      <button className="converstion-close-btn" onClick={setShowChat}>x</button>
    </div>
  );
}

export default ChatApp;
