import ReactDOM from 'react-dom/client'
import App from './App'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter } from "react-router-dom";
import './index.css'
import Socket from './socket/Socket';

library.add(faUser);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
        <Socket>
            <App />
        </Socket>
    </BrowserRouter>
)
