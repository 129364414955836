
const ThankYouPage = () => {
    return (
        <div className="thank-you-container">
            <h1 className="thank-you-header">Thank You!</h1>
            <p className="thank-you-message">Your call has ended successfully.</p>
            <p className="thank-you-message">We hope to see you again soon!</p>
            <div>
                <a href="/">Back To Home</a>
            </div>
        </div>
    );
};

export default ThankYouPage;
