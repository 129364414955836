import Background from "../assets/background/video-big.mp4";
import CompanyLogo from "../assets/images/company-logo.png";
import { useNavigate } from "react-router-dom";
import  Passwordhidden  from "../assets/images/eyeicon.svg";
import { useState } from "react";
import Passwordview from "../assets/images/password-view.svg"
import useToggle from "../hooks/useToggle";

const LoginCard = () => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [showPassword, setShowPassword] = useToggle(false);

    const navigate=useNavigate()

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };

      const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (formData.username === 'prasana' && formData.password === 'prasana') {
             localStorage.setItem('user', '65df1dd3295c753309b534c5')
            navigate('/')
        } else {
          alert('Invalid username or password');
        }
      };
    

    return (
        <form onSubmit={handleLogin}>
            <video style={{ display: "block" }} autoPlay muted loop id="bgVideo" className="bgVideo">
                <source src={Background} type="video/mp4" />
                Your browser does not support HTML5 video.
            </video>
            <div className="content" id="login-container">
                <img src={CompanyLogo} alt="logo" id="companyLogo"/>
                <h3>Welcome to 2nd Sight AI</h3>
                <p>Login to join in your interview</p>
                <div className="common-view">
                    <div className="form-group">
                        <input type="text" placeholder="" id="username" name="username" onChange={handleInputChange}/>
                        <label htmlFor="username">User ID</label>
                    </div>
                    <div className="form-group">
                    <input type={showPassword ? "text" : "password"} placeholder="" id="password" name="password" onChange={handleInputChange} />
                        <label htmlFor="password">Password</label>
                        <div className="passwordviewpos" onClick={setShowPassword}>
                        <img src={showPassword ? Passwordview : Passwordhidden} alt="" />
                        </div>
                    </div>

                    <div className="text-center">
                       <button className="login-btn">Login</button>
                    </div>
                    <div className="forgotView">
                        <a className="">Forgot Password?</a>
                        <p className="">Don't have an account: <a>Sign Up</a></p>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default LoginCard