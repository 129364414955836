import { ChangeEvent, useEffect, useRef, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import RecordRTC from 'recordrtc';
import { sendDataToServerWithAudio, sendDataToServerWithSocket2 } from '../helper/socketEmit';
import { submitAnswer } from '../helper/submitAnswer';
import useToggle from '../hooks/useToggle';
import { useSocketContext } from '../socket/Socket';
import CallOptions from './Call-options';
import WebcamCapture from './WebCam';

declare global {
    interface Window {
        ImageCapture: any;
    }
}


function TermsAndConditions() {
    const [accepted, setAccepted] = useState(false);
    const [clicked, setClicked] = useToggle(false)
    const [timeLeft, setTimeLeft] = useState(10);
    const [timerActive, setTimerActive] = useState(false);
    const [stream, setStream] = useState<MediaStream | null>(null);
    // const [combinedStream, setCombinedStream] = useState<MediaStream | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const [recorder, setRecorder] = useState<any>(null);
    const { socket1, socket2, userId } = useSocketContext();
    const [question, setQuestion] = useState<any[]>([]);
    const [videoEnabled, setVideoEnabled] = useState(false);
    const [runCount, setRunCount] = useState(0);
    const [changeText, setChangeText] = useState(false)


    const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAccepted(event.target.checked);
    };

    const handleStartInterview = async () => {
        if (accepted) {
            setClicked();
        }
    };

    const handleDataAvailable = async (id: any, name: any, userdata: any) => {
        const arrayBuffer = await userdata.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        let binaryString = '';
        uint8Array.forEach(byte => {
            binaryString += String.fromCharCode(byte);
        });
        const base64_chunk = btoa(binaryString);
        const data = { userId: id, userName: name, chunk: base64_chunk };
        sendDataToServerWithSocket2(socket2, 'data', data);
    };


    let intervalId: NodeJS.Timeout | null = null;

    const handleVideoToggle = async () => {
        const newVideoEnabled = !videoEnabled;
        setVideoEnabled(newVideoEnabled);
        // handleVideo(newVideoEnabled, true, true);
    }

    const handleVideo = async () => {

        const constraints = {
            video: true,
            audio: true
        };
        const data = { userId: userId, userName: process.env.REACT_APP_USER_NAME };
        sendDataToServerWithSocket2(socket2, 'start', data);

        try {
            // Get screen sharing stream (video and audio)
            // const screenMediaStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });

            // Get user's media stream (video and audio)
            const userStream = await navigator.mediaDevices.getUserMedia(constraints);
            setStream(userStream);

            // Create a new MediaStream for combined video and audio
            // const combinedStream = new MediaStream();

            // Add tracks from screen sharing stream to combined stream
            // screenMediaStream.getTracks().forEach(track => {
            //     combinedStream.addTrack(track);
            // });

            // Add audio track from user's media stream to combined stream
            // userStream.getAudioTracks().forEach(track => {
            //     combinedStream.addTrack(track);
            // });

            // setCombinedStream(combinedStream);
            if (userStream) {
                // Create MediaRecorder with combined stream
                const mimeType = 'video/webm; codecs="opus,vp8"';
                const options: MediaRecorderOptions = { type: mimeType } as MediaRecorderOptions;
                const recorder = new MediaRecorder(userStream, options);
                mediaRecorderRef.current = recorder;
                recorder.start(1000);

                // Handle data available event from MediaRecorder
                recorder.ondataavailable = (event: { data: { size: number }; }) => {
                    if (event.data && event.data.size > 0) {
                        handleDataAvailable(userId, process.env.REACT_APP_USER_NAME, event.data);
                    }
                };
                // Start grabbing frames from user's media stream
                startGrabbingFrames(userStream);
            } else {
                console.error('Failed to obtain MediaStream');
            }
            // Toggle video recording
            handleVideoToggle();
        } catch (error) {
            console.error('Error accessing media devices:', error);
        }
    };


    const startGrabbingFrames = (stream: any) => {
        intervalId = setInterval(() => {
            grabFrame(stream);
        }, 100); // Adjust the interval as needed
    };

    const clearGrabFrameInterval = () => {
        if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
        }
    }

    const grabFrame: any = async (stream: any) => {
        try {
            if (stream && stream.active) {
                const track = stream.getVideoTracks()[0];
                if (track && track.readyState === 'live') {
                    const imageCapture = new window.ImageCapture(track);
                    const imageBitmap = await imageCapture.grabFrame();
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d')!;
                    canvas.width = imageBitmap.width;
                    canvas.height = imageBitmap.height;
                    context.drawImage(imageBitmap, 0, 0);
                    const imageData = canvas.toDataURL('image/jpeg');
                    const data = { userId: userId, userName: process.env.REACT_APP_USER_NAME, chunk: imageData };
                    const formattedEventType = `data_video_frame_stream`;
                    socket2.emit(formattedEventType, data);
                    console.log('sent', formattedEventType);
                } else {
                    console.warn('Video track is not in a valid state');
                }
            }
        } catch (error) {
            console.error('Error grabbing frame:', error);
        }
    };


    useEffect(() => {
        socket1.on('start_timer', (data: any) => {
            if (data) {
                setQuestion([{ ...data, type: 'sender' }]);
                setTimerActive(true);
                // setPreparationTime(true)
            }
        });
        socket2.on('distraction', (data: any) => {
            const videoPlayer: any = document.querySelector('.video');
            console.log(data, "distraction_datas")
            // Changing the border color of the video to red
            videoPlayer.style.border = '10px solid red';

            // Set a timeout to change the border color back after 5 seconds
            setTimeout(() => {
                // Assuming the original border color is greenyellow as per your CSS
                videoPlayer.style.border = '10px solid greenyellow';
            }, 500);
        });
        return () => {
            socket1.off('start_timer');
            socket2.off('distraction')
        };
    }, [socket1, socket2]);


    useEffect(() => {
        let countdownTimer: any;

        if (timerActive && runCount < 2) {
            countdownTimer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime === 0) {
                        SpeechRecognition.stopListening();
                        setTimeLeft(20);
                        setChangeText(true)
                        // resetTranscript();
                        if (recorder) {
                            stopRecording();
                        }
                        setRunCount(runCount + 1);
                        if (runCount + 1 === 2) {
                            setTimerActive(false);
                            submitAnswer(transcript, userId);
                            resetTranscript();
                            setRunCount(0)
                            setTimeLeft(10);
                            setChangeText(false)
                        }
                        return prevTime;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(countdownTimer);
        };
    }, [timerActive, timeLeft, runCount]);

    const handleAnswer = async () => {
        SpeechRecognition.startListening({ continuous: true })
        startRecording()
        if (runCount === 0) {
            setTimeLeft(20);
            setChangeText(true)
        }
        setRunCount(1)
    }


    const startRecording = () => {
        const data = { userId: userId, userName: process.env.REACT_APP_USER_NAME };
        sendDataToServerWithAudio(socket2, 'start', data);

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const newRecorder = new RecordRTC(stream, {
                    mimeType: 'audio/wav;codecs=pcm' as 'audio/webm;codecs=pcm',
                    type: 'audio',
                    recorderType: RecordRTC.StereoAudioRecorder,
                    desiredSampRate: 16000,
                    audioBitsPerSecond: 256000,
                    numberOfAudioChannels: 1,
                    timeSlice: 50,
                    ondataavailable: (blob) => {
                        console.log(blob, "blobData")
                        const data = { userId: userId, userName: process.env.REACT_APP_USER_NAME, chunk: blob };
                        sendDataToServerWithAudio(socket2, "data", data)
                    }
                });

                newRecorder.startRecording();
                setRecorder(newRecorder);

                console.log('Recording started');
            }).catch(error => {
                console.error('Error accessing microphone:', error);
            });
    };

    const stopRecording = () => {
        if (recorder) {
            SpeechRecognition.stopListening();
            recorder.stopRecording(() => {
                console.log('Recording stopped');
                // const audioBlob = recorder.getBlob();
                const data = { userId: userId, userName: process.env.REACT_APP_USER_NAME };
                sendDataToServerWithAudio(socket2, 'end', data);

                if (recorder.stream) {
                    recorder.stream.getTracks().forEach((track: { stop: () => void; }) => {
                        track.stop();
                    });
                    setRecorder(null)
                }

            });
        }

    };

    useEffect(() => {
        socket1.on('start_video_recording', (data: any) => {
            if (data) {

                console.log('video enabled')
                handleVideo()
            }
        });
        return () => {
            socket1.off('start_video_recording');
        };
    }, [socket1]);


    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const buttonStyle = {
        backgroundColor: accepted ? '#1A9FFF' : '#d1d2d4',
        color: accepted ? '#F6F9FF' : '#000000',
        border: accepted ? 'solid 1px #1A9FFF' : 'solid 1px #000000',
        cursor: accepted ? 'pointer' : 'not-allowed',
        display: 'flex'
    };

    return (
        <div id={clicked ? "" : "overlay"}>
            <div className="term-main-container-div" >

                {<WebcamCapture videoEnabled={videoEnabled} stream={stream} />}
                {clicked ?
                    <>

                        <CallOptions handleAnswer={handleAnswer} changeText={changeText} timeLeft={timeLeft} transcript={transcript} setChangeText={setChangeText} setRunCount={setRunCount} runCount={runCount} setTimeLeft={setTimeLeft} setTimerActive={setTimerActive} timerActive={timerActive} resetTranscript={resetTranscript} stream={stream} setStream={setStream} handleVideo={handleVideo} mediaRecorderRef={mediaRecorderRef} recorder={recorder} stopRecording={stopRecording} question={question} clearGrabFrameInterval={clearGrabFrameInterval} handleVideoToggle={handleVideoToggle} videoEnabled={videoEnabled} setVideoEnabled={setVideoEnabled} />
                    </>
                    : <div id="term-output" className="text-container">
                        <h1>Instructions</h1>
                        <div className="scroll-section">
                            <ol>
                                <li>Introduction: You'll be greeted by an AI avatar. Pay attention to the instructions and welcome message.</li>
                                <li>Avatar Assistance: The AI avatar may provide real-time feedback or follow-up questions based on your responses. Embrace the avatar's assistance to enhance your interview experience.</li>
                                <li>Stay Focused: Maintain your concentration throughout the interview. Avoid distractions and do not leave the interview screen.</li>
                                <li>Ask Questions (if allowed): At the end of the interview, you may have the opportunity to ask questions. Feel free to inquire about the position or organization.</li>
                                <li>End the Interview: The avatar will conclude the interview professionally. Thank the avatar for the opportunity.</li>
                                <li>Post-Interview: You will receive feedback and evaluation results, if applicable, after the interview.</li>
                                <li>Support and Assistance: If you encounter any technical issues or have questions during the interview, contact our support team at [support@2ndsight.ai].</li>
                            </ol>
                            <div className="footer-terms-btn-container" id="footerBtnDiv">
                                <div className='che-input'>
                                    <input type="checkbox" id="acceptTermsCheckbox" onChange={handleCheckboxChange} />
                                    <label htmlFor="acceptTermsCheckbox" className="acceptCheckboxlabel">Accept the terms and conditions</label>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button onClick={() => handleStartInterview()} className="btn-start-interview" style={buttonStyle}>
                                        {accepted ? 'Start the interview' : 'Accept terms to start'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    );
}

export default TermsAndConditions;