import Unity from "../assets/images/unity.jpg";
import ML from "../assets/images/ml.jpg";
import { Link } from "react-router-dom";

const DesignationCard = () => {

    
   
    return (
        <div>
            <div className="grid-container">
                <div className="grid-item" >
                    <Link to='/interview'> <img src={Unity} className="grid-image" /></Link>
                </div>
                <div className="grid-item">
                    <Link to='/interview'><img src={ML} className="grid-image" /></Link>
                </div>
            </div>
        </div>
    )
}

export default DesignationCard