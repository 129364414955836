import { useEffect, useRef } from 'react';
import { VideoCamProps } from '../Types';

const WebcamCapture: React.FC<VideoCamProps> = ({ videoEnabled, stream,  }) => {

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }

  }, [stream,videoEnabled]);

  return (
    <div className='webcam' id='webcam'>
      {videoEnabled?<video className="video" ref={videoRef} autoPlay muted style={{ border: videoEnabled ? "10px solid greenyellow" : "none" }}/>:null}
    </div>
  );
};

export default WebcamCapture;
