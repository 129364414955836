export const sendDataToServer = (socket1: any,eventType: string,data: any) => {
  if(eventType==='start_interview'){
    console.log('hi')
    socket1.emit(eventType, data)
  }else{
    const formattedEventType = `${eventType}_video_stream`;
    // console.log("Emitted Data from socket:", data);
    socket1.emit(formattedEventType, data);
  }

};

export const sendDataToServerWithSocket2 = (socket2: any,eventType: string,data: any) => {
  const formattedEventType = `${eventType}_video_stream`;
  socket2.emit(formattedEventType, data);
  console.log("video chunks",formattedEventType)
};


export const sendDataToServerWithAudio = (socket2: any,eventType: string,data: any) => {

  const formattedEventType = `${eventType}_audio_stream`;
  socket2.emit(formattedEventType, data);
  // console.log('event',formattedEventType,data)
};