import { useEffect } from "react";
import JobCard from "../components/Desgination-card";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isFirstTime = localStorage.getItem("isFirstTime");
    if (!isFirstTime) {
      // Set the flag indicating it's not the first time
      localStorage.setItem("isFirstTime", "false");
      // Reload the page
      window.location.reload();
    } else {
      if (!localStorage.getItem("user")) {
        navigate("/login");
      }
    }
  }, []);

  return (
    <div>
      <NavBar />
      <JobCard />
    </div>
  );
};

export default Home;
