import LoginCard from "../components/Login-card"

const Login = () => {
  return (
    <div>
      <LoginCard/>
    </div>
  )
}

export default Login