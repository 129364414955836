import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompanyLogo from "../assets/images/company-logo.png";
import useToggle from '../hooks/useToggle';

const NavBar = () => {
  const [isOpen, toggleDropdown] = useToggle(false);

  return (
    <header>
      <div className="logo">
        <img src={CompanyLogo} alt="Logo" className="logo-image" />
      </div>
      <div className="circle-button-container">
        <button className="circle-button" onClick={toggleDropdown}><FontAwesomeIcon icon="user" /></button>
        <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
          <a>Profile</a>
          <a>Log Out</a>
        </div>
      </div>
    </header>
  )
}

export default NavBar