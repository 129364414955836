import TermsAndConditions from "../components/Terms-conditions"


const InterviewScreen = () => {

    console.log(process.env.REACT_APP_PRODUCTION_URL, "production url is used for iframe")

    console.log(process.env.REACT_APP_DEVELOPMENT_URL, "Development url is used for iframe")

    localStorage.removeItem("isFirstTime");


    return (
        <div className="background-wrapper" >
            <iframe width="1000"
                height="1000" src={process.env.REACT_APP_PRODUCTION_URL}
                title="Inline Frame Example" sandbox="allow-scripts allow-same-origin"></iframe>
            <TermsAndConditions />
        </div>
    )
}

export default InterviewScreen