import { Routes, Route } from "react-router-dom"
import Login from "./pages/Login"
import Home from "./pages/Home"
import InterviewScreen from "./pages/InterviewScreen"
import ThankYouPage from "./pages/Thankyou"


const App = () => {
 
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path='/interview' element={<InterviewScreen />} />
        <Route path='/thankyou' element={<ThankYouPage />} />
      </Routes>
    </>
  )
}

export default App