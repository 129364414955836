import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SpeechRecognition from 'react-speech-recognition';
import { transcriptProps } from "../Types";
import ArrowIcon from "../assets/icons/keyboard_double_arrow.png";
import CallIcon from "../assets/icons/microphone.png";
import CaptionIcon from "../assets/icons/screenShareIcon.png";
import VideoIcon from "../assets/icons/video.png";
import ChatIcon from '../assets/images/chat.svg';
import { sendDataToServer, sendDataToServerWithSocket2 } from "../helper/socketEmit";
import { submitAnswer } from "../helper/submitAnswer";
import useToggle from "../hooks/useToggle";
import { useSocketContext } from "../socket/Socket";
import ChatApp from "./Chat-app";


const CallOptions: React.FC<transcriptProps> = ({ timeLeft, changeText, handleAnswer, transcript, setChangeText, runCount, setRunCount, setTimeLeft, setTimerActive, timerActive, resetTranscript, stream, setStream, mediaRecorderRef, recorder, stopRecording, question, clearGrabFrameInterval, handleVideoToggle, videoEnabled, setVideoEnabled, }) => {

    const [audioEnabled, setAudioEnabled] = useState(true)
    const [showTranscript, setShowTranscript] = useToggle(true)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showChat, setShowChat] = useToggle(false);
    const { socket1, socket2, userId } = useSocketContext();
    const callEndButtonRef = useRef<HTMLButtonElement>(null);
    const [interviewStatus, setInterviewStatus] = useState('Start');

    const navigate = useNavigate()

    // const userId = localStorage.getItem('user')

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            document.documentElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
        setIsFullScreen(!isFullScreen);
    };



    const handleCallEnd = () => {
        setAudioEnabled(false);
        setVideoEnabled(false);
        stopMedia()
        clearGrabFrameInterval()
        SpeechRecognition.stopListening();
        if (recorder) {
            console.log("stopped recording")
            stopRecording()
        }
        setTimeout(() => {
            navigate('/thankyou');
        }, 0);
    }

    const stopMedia = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.onstop = () => {
                const data = { userId: userId, userName: process.env.REACT_APP_USER_NAME };

                sendDataToServerWithSocket2(socket2, 'end', data);
            };
            mediaRecorderRef.current.stop();
        }

        if (stream) {
            console.log('Webcam sharing stopped')
            stream.getTracks().forEach(track => track.stop());
            setStream(null);
        }
        // if (combinedStream) {
        //     console.log('Screen sharing stopped')
        //     combinedStream.getTracks().forEach(track => track.stop());
        //     setCombinedStream(null);
        // }
    };

    const handleNextButton = async () => {
        if (interviewStatus === 'Next') {
            setTimeLeft(10)
            setTimerActive(false)
            resetTranscript()
            stopRecording()
            setRunCount(0)
            setChangeText(false)
            await submitAnswer(transcript,userId)
        }

        if (interviewStatus === 'Start') {
            setInterviewStatus('Next')
            sendDataToServer(socket1, "start_interview", { id: userId })
            console.log("start_interview emitted", interviewStatus)
        }

    }

    useEffect(() => {
        socket1.on('start_timer', (data: any) => {
            if (data) {
                // setPreparationTime(true)
                setTimerActive(true);
            }
        });

        socket1.on('thank_you', (data: any) => {
            if (data) {
                // Check if the ref exists and then click the button
                if (callEndButtonRef.current) {
                    callEndButtonRef.current.click();
                }
            }
        });

        return () => {
            socket1.off('start_timer');
            socket1.off('thank_you');
        };
    }, [socket1]);



    const [messages, setMessages] = useState<any[]>([])

    useEffect(() => {
        if (question && question.length > 0) {
            console.log(question)
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
            setMessages(prevMessages => {
                const newMessages = question.map((q: { key: any; }) => ({ type: 'sender', text: q.key, timestamps: formattedDate }));
                return [...prevMessages, ...newMessages];
            });
        }
    }, [question]);

    useEffect(() => {
        socket1.on('transcription', (data: any) => {
            if (data) {
                console.log(data)
                const currentDate = new Date();
                const formattedDate = currentDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
                setMessages(prevMessages => [...prevMessages, { type: 'receiver', text: data.transcript, timestamps: formattedDate }]);
            }
        });

        return () => {
            socket2.off('transcription');
        };

    }, [socket2]);


    return (
        <div>
            {showChat && <div style={{ height: "100vh" }}>
                <ChatApp showChat={showChat} setShowChat={setShowChat} messages={messages} />
            </div>}
            <div id='controls' className="call-options-container">
                <div className="caption">
                    <span>{showTranscript ? transcript : ""}</span>
                </div>
                <div className="box">
                    <div className="chatsBtnsContainer" onClick={setShowChat}>
                        <button className="chatsBtns">
                            <img src={ChatIcon} alt='' />
                        </button>

                    </div>
                    <div className="buttons">
                        <a className="full-screens" id='fullscreen-btn' onClick={toggleFullScreen}>
                            <svg id="maximizeIcon" x="0px" y="0px" viewBox="0 0 384.97 384.97" style={{ display: "inline-block", width: "24px", height: "24px", verticalAlign: "middle", color: "white" }}>
                                <g className="svgIcon">
                                    <path d="M384.97,12.03c0-6.713-5.317-12.03-12.03-12.03H264.847c-6.833,0-11.922,5.39-11.934,12.223c0,6.821,5.101,11.838,11.934,11.838h96.062l-0.193,96.519c0,6.833,5.197,12.03,12.03,12.03c6.833-0.012,12.03-5.197,12.03-12.03l0.193-108.369c0-0.036-0.012-0.06-0.012-0.084C384.958,12.09,384.97,12.066,384.97,12.03z"></path><path d="M120.496,0H12.403c-0.036,0-0.06,0.012-0.096,0.012C12.283,0.012,12.247,0,12.223,0C5.51,0,0.192,5.317,0.192,12.03L0,120.399c0,6.833,5.39,11.934,12.223,11.934c6.821,0,11.838-5.101,11.838-11.934l0.192-96.339h96.242c6.833,0,12.03-5.197,12.03-12.03C132.514,5.197,127.317,0,120.496,0z"></path><path d="M120.123,360.909H24.061v-96.242c0-6.833-5.197-12.03-12.03-12.03S0,257.833,0,264.667v108.092c0,0.036,0.012,0.06,0.012,0.084c0,0.036-0.012,0.06-0.012,0.096c0,6.713,5.317,12.03,12.03,12.03h108.092c6.833,0,11.922-5.39,11.934-12.223C132.057,365.926,126.956,360.909,120.123,360.909z"></path><path d="M372.747,252.913c-6.833,0-11.85,5.101-11.838,11.934v96.062h-96.242c-6.833,0-12.03,5.197-12.03,12.03s5.197,12.03,12.03,12.03h108.092c0.036,0,0.06-0.012,0.084-0.012c0.036-0.012,0.06,0.012,0.096,0.012c6.713,0,12.03-5.317,12.03-12.03V264.847C384.97,258.014,379.58,252.913,372.747,252.913z"></path>        </g>
                            </svg>
                        </a>
                        <a className="capture-screen" id='shareScreenBtn' onClick={setShowTranscript}>
                            <img src={CaptionIcon} alt='' id='screenShareIcon' className="icons" />
                        </a>
                        <button className="videos" ref={callEndButtonRef} id='micBtn' onClick={handleCallEnd}>
                            <img src={CallIcon} alt='' id='micIcon' className="icons" />
                        </button>
                        <a className="microphone" id='videoCallBtn' onClick={handleVideoToggle}>
                            <img src={VideoIcon} alt='' id='videoCallIcon' className="icons" />
                        </a>
                        <button className="next-icons" id='callEndBtn' onClick={handleNextButton} disabled={!timerActive && interviewStatus === 'Next'}>
                            {interviewStatus === 'Next' ?
                                <>
                                    <span>{interviewStatus}</span>
                                    <img src={ArrowIcon} alt='' id='callEndIcon' className="icons" />
                                </> :
                                interviewStatus
                            }
                        </button>
                    </div>
                    <p style={{ color: 'white', fontSize: '16px' }}>{runCount === 0 && timerActive ? 'Prep Time:' : ''}</p>
                    <div className='countdown_container' id='countdown-01'>
                        <span className='timer' >0:0:{timeLeft}</span>
                        <button id="answer-btn-01" className="answer_btn" onClick={handleAnswer} disabled={!timerActive}>{changeText ? 'Answer' : 'Click To Answer'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallOptions;